import React from 'react';
import { Link } from 'react-router-dom';

import ContentBlock from '../../components/ContentBlock';

import ORIGINAL_DRAWING_01 from '../../assets/project-photos/modern-china/original_drawing_01.png';

let ModernChinaPage = () => (
  <div>
    <ContentBlock theme='light'>
      <div className="page-heading">
        <span className="page-heading-title">
          Modern China
        </span>
        <span className="page-heading-subtitle">
          A simple dinning table
        </span>
      </div>

      <a href={ORIGINAL_DRAWING_01} target="_blank">
        <div className="full-width-photo" style={{
          backgroundImage: `url(${ORIGINAL_DRAWING_01})`,
        }}/>
      </a>

      <div className="page-abstract">
        <p>
          Modern China is a medium-sized dining table inspired by a foreigner’s interpretation of Chinese history and culture combined with elements from its contemporary times.
        </p>
      </div>

      <div className="highlight">
        <b>Please note:</b> The drawings are of the side view of the project.
      </div>

    </ContentBlock>
  </div>
);

export default ModernChinaPage;
