import ContentBlock from '../components/ContentBlock';

let NotReadyYetPage = () => (
  <ContentBlock>
    <div className="not-ready-yet-notice">
      Sorry, this page isn't quite ready yet :(((
    </div>
  </ContentBlock>
);

export default NotReadyYetPage;
