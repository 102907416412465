import React from 'react';
import { Link } from 'react-router-dom';

import ContentBlock from '../components/ContentBlock';
import "../assets/styles/pages/ProjectsPage.css";

let ProjectsPage = () => (
  <ContentBlock theme='light'>
{/*
    <h3><span>Featured Project</span></h3>
    <div className="featured-project">
      <div className="featured-project-cover">
        <Link to="/projects/never-forgotten">
          <div className="never-forgotten-showcase-cover">
          </div>
        </Link>
      </div>
      <div className="featured-project-text">
        <b>The 'Never Forgotten' Desk</b>
        <br />
        <p>
          The Never Forgotten is a desk inspired by aviation. The side-profile somewhat loosely replicates the side-profile of a biplane; However, it deliberately doesn’t copy the exact elements, forms or the construction methods of an airworthy aeroplane.        </p>
        <p>
          The design is a tribute to the brave people who not only made flying and aviation possible, safer and more accessible for all of us, but also protected our lives and future at the cost of sacrificing their own.
        </p>
        <Link to="/projects/never-forgotten">Read more...</Link>
      </div>
    </div>

    <h3><span>Most Recent</span></h3>
    <div className="most-recent-project">
      <div className="most-recent-project-cover">
        <Link to="/projects/one-liner">
          <div className="one-liner-showcase-cover">
          </div>
        </Link>
      </div>
      <div className="most-recent-project-text">
        <b>The 'One Liner' Stool</b>
        <br />
        <p>

        </p>
      </div>
    </div>
*/}
    <h3><span>Most Recent</span></h3>
    <div className="showcase">
      <div className="showcase-element">
        <Link to="/projects/one-liner">
          <div className="showcase-element-photo one-liner-showcase-cover">
            <span>One-Liner</span>
          </div>
        </Link>
      </div>
      <div className="showcase-element">
        <Link to="/projects/never-forgotten">
          <div className="showcase-element-photo never-forgotten-showcase-cover">
            <span>Never Forgotten</span>
          </div>
        </Link>
      </div>
      <div className="showcase-element"></div>
    </div>
{/*
    <h3><span>Work-in-Progress</span></h3>
    <div className="showcase">
      <div className="showcase-element">
        <Link to="/projects/symbol">
          <div className="showcase-element-photo symbol-showcase-cover">
            <span>Symbol</span>
          </div>
        </Link>
      </div>
      <div className="showcase-element">
        <Link to="/projects/monk">
          <div className="showcase-element-photo monk-showcase-cover">
            <span>Monk</span>
          </div>
        </Link>
      </div>
      <div className="showcase-element">
        <Link to="/projects/yuanyuan">
          <div className="showcase-element-photo yuanyuan-showcase-cover">
            <span>Yuanyuan's Bench</span>
          </div>
        </Link>
      </div>
    </div>

    <h3><span>Upcoming</span></h3>
    <div className="showcase">
      <div className="showcase-element">
        <Link to="/projects/roma">
          <div className="showcase-element-photo roma-showcase-cover">
            <span>Roma</span>
          </div>
        </Link>
      </div>
      <div className="showcase-element">
        <Link to="/projects/phantom">
          <div className="showcase-element-photo phantom-showcase-cover">
            <span>Phantom</span>
          </div>
        </Link>
      </div>
      <div className="showcase-element">
        <Link to="/projects/arrow">
          <div className="showcase-element-photo arrow-showcase-cover">
            <span>Arrow</span>
          </div>
        </Link>
      </div>
      {/*<div className="showcase-element">
        <Link to="/projects/modern-china">
          <div className="showcase-element-photo modern-china-showcase-cover">
            <span>Modern China</span>
          </div>
        </Link>
      </div>
    </div>
*/}
  </ContentBlock>
);

export default ProjectsPage;
