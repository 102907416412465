import React from 'react';
import { Link } from 'react-router-dom';

import ContentBlock from '../../components/ContentBlock';

import ORIGINAL_DRAWING_01 from '../../assets/project-photos/roma/original_drawing_01.jpg';
import ORIGINAL_DRAWING_02 from '../../assets/project-photos/roma/original_drawing_02.jpg';

let RomaPage = () => (
  <div>
    <ContentBlock theme='light'>
      <div className="page-heading">
        <span className="page-heading-title">
          The Roma Desk
        </span>
        {/*<span className="page-heading-subtitle">
          
        </span>*/}
      </div>

      <a href={ORIGINAL_DRAWING_01} target="_blank">
        <div className="full-width-photo" style={{
          backgroundImage: `url(${ORIGINAL_DRAWING_01})`,
        }}/>
      </a>

      <div className="page-abstract">
        <p>
          Roma is still a design concept. The goal was to design a desk for a visual artist or an architect. This project is still at the experimental stage.
        </p>
      </div>
      
      <div className="highlight">
        <b>Please note:</b> The drawings are of the side view of the project.
      </div>

    </ContentBlock>

    <ContentBlock>
      <h3>
        <span>Drawings</span>
      </h3>
      <div className="photo-gallery">
        <a href={ORIGINAL_DRAWING_01} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${ORIGINAL_DRAWING_01})`,
          }}/>
        </a>

        <a href={ORIGINAL_DRAWING_02} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${ORIGINAL_DRAWING_02})`,
          }}/>
        </a>
      </div>
    </ContentBlock>
  </div>
);

export default RomaPage;
