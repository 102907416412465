import React from 'react';
import { Link } from 'react-router-dom';

import ContentBlock from '../../components/ContentBlock';

import ORIGINAL_DRAWING_01 from '../../assets/project-photos/yuanyuan/original_drawing_01.jpg';

let MonkPage = () => (
  <div>
    <ContentBlock theme='light'>
      <div className="page-heading">
        <span className="page-heading-title">
          The Yuanyuan's Bench
        </span>
        <span className="page-heading-subtitle">
          A small outdoor bench
        </span>
      </div>

      <a href={ORIGINAL_DRAWING_01} target="_blank">
        <div className="full-width-photo" style={{
          backgroundImage: `url(${ORIGINAL_DRAWING_01})`,
        }}/>
      </a>

      <div className="page-abstract">
        <p>
          This is a bespoke design and build for a client. The idea was to incorporate the letter Y in the design, ideally in two locations and interconnected. The bench is relatively small and is being made out of Iroko. This is a work-in-Progress project.
        </p>
      </div>
      
      <div className="highlight">
        <b>Please note:</b> The drawings are of the side view of the project.
      </div>

    </ContentBlock>
  </div>
);

export default MonkPage;
