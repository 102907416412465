import React from 'react';
import { Link } from 'react-router-dom';

import ContentBlock from '../../components/ContentBlock';

import ORIGINAL_DRAWING_01 from '../../assets/project-photos/phantom/original_drawing_01.png';
import ORIGINAL_DRAWING_02 from '../../assets/project-photos/phantom/original_drawing_02.png';

let PhantomPage = () => (
  <div>
    <ContentBlock theme='light'>
      <div className="page-heading">
        <span className="page-heading-title">
          The Phantom Executive Desk
        </span>
        {/*<span className="page-heading-subtitle">
          
        </span>*/}
      </div>

      <a href={ORIGINAL_DRAWING_02} target="_blank">
        <div className="full-width-photo" style={{
          backgroundImage: `url(${ORIGINAL_DRAWING_02})`,
        }}/>
      </a>

      <div className="page-abstract">
        <p>
          The Phantom is an executive desk inspired by 1950s and 1960s twin-engine jet fighters and interceptors.
        </p>
      </div>

      <div className="highlight">
        <b>Please note:</b> The drawings are of the side view of the project.
      </div>

    </ContentBlock>

    <ContentBlock>
      <h3>
        <span>Drawings</span>
      </h3>
      <div className="photo-gallery">
        <a href={ORIGINAL_DRAWING_01} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${ORIGINAL_DRAWING_01})`,
          }}/>
        </a>

        <a href={ORIGINAL_DRAWING_02} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${ORIGINAL_DRAWING_02})`,
          }}/>
        </a>
      </div>
    </ContentBlock>
  </div>
);

export default PhantomPage;
