import React from 'react';
import { Link } from 'react-router-dom';

import ContentBlock from '../../components/ContentBlock';

import ORIGINAL_DRAWING_01 from '../../assets/project-photos/arrow/original_drawing_01.png';
import ORIGINAL_DRAWING_02 from '../../assets/project-photos/arrow/original_drawing_02.png';

let ArrowPage = () => (
  <div>
    <ContentBlock theme='light'>
      <div className="page-heading">
        <span className="page-heading-title">
          The Arrow
        </span>
        <span className="page-heading-subtitle">
          A simple computer desk
        </span>
      </div>

      <a href={ORIGINAL_DRAWING_02} target="_blank">
        <div className="full-width-photo" style={{
          backgroundImage: `url(${ORIGINAL_DRAWING_02})`,
        }}/>
      </a>

      <div className="page-abstract">
        <p>
          The Arrow is an aviation-inspired desk designed for the ease of manufacturing and possibly production in high numbers.
        </p>
      </div>

      <div className="highlight">
        <b>Please note:</b> The drawings are of the side view of the project.
      </div>

    </ContentBlock>

    <ContentBlock>
      <h3>
        <span>Drawings</span>
      </h3>
      <div className="photo-gallery">
        <a href={ORIGINAL_DRAWING_01} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${ORIGINAL_DRAWING_01})`,
          }}/>
        </a>

        <a href={ORIGINAL_DRAWING_02} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${ORIGINAL_DRAWING_02})`,
          }}/>
        </a>
      </div>
    </ContentBlock>
  </div>
);

export default ArrowPage;
