import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';

import HomePage from './pages/HomePage';
import ProjectsPage from './pages/ProjectsPage';
import NotesPage from './pages/NotesPage';
import ContactPage from './pages/ContactPage';
import NotReadyYetPage from './pages/NotReadyYetPage';
import NeverForgottenPage from './pages/projects/NeverForgottenPage';
import OneLinerPage from './pages/projects/OneLinerPage';
import SymbolPage from './pages/projects/SymbolPage';
import RomaPage from './pages/projects/RomaPage';
import MonkPage from './pages/projects/MonkPage';
import YuanyuanPage from './pages/projects/YuanyuanPage';
import PhantomPage from './pages/projects/PhantomPage';
import ArrowPage from './pages/projects/ArrowPage';
import ModernChinaPage from './pages/projects/ModernChinaPage';
import NotAboutMoneyPage from './pages/notes/NotAboutMoneyPage';
import OrderPage from './pages/OrderPage';
import ThankYouPage from './pages/ThankYouPage';

import ContentBlock from './components/ContentBlock';

import logo_dark from './assets/vectors/klyn-logo-dark.svg';
import logo_light from './assets/vectors/klyn-logo-light.svg';
import './assets/styles/App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange({ target }) {
    this.setState({
      email: target.value
    });
  }

  render() {
    return (
      <Router>
        <div className="App">
          <header className="App-header">
            <Link to="/" className="App-logo">
              <img src={logo_light} alt="logo" />
            </Link>
            {/*<span className="App-tagline">not about money</span>*/}
            <ul className="App-menu no-list-style">
              <li>
                <Link to="/projects">Projects</Link>
              </li>
              <li>
                <Link to="/notes">Notes</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </ul>
            <div className='Header-headline'>
              <div className='Headline-one'>
                <span>Your Space.</span>
                <span>Your Style.</span>
              </div>
            </div>
            <div className='Header-call-to-action'>
              <span>Intrested?</span>
              <Link to='/order'>
                <button>Order Here</button>
              </Link>
            </div>
          </header>
          <div className="App-content">
            <Switch>
              <Route path="/projects/never-forgotten">
                <NeverForgottenPage />
              </Route>
              <Route path="/projects/one-liner">
                <OneLinerPage />
              </Route>
              <Route path="/projects/symbol">
                <SymbolPage />
              </Route>
              <Route path="/projects/roma">
                <RomaPage />
              </Route>
              <Route path="/projects/monk">
                <MonkPage />
              </Route>
              <Route path="/projects/yuanyuan">
                <YuanyuanPage />
              </Route>
              <Route path="/projects/phantom">
                <PhantomPage />
              </Route>
              <Route path="/projects/arrow">
                <ArrowPage />
              </Route>
              <Route path="/projects/modern-china">
                <ModernChinaPage />
              </Route>
              <Route path="/notes/not-about-money">
                <NotReadyYetPage />
                {/*<NotAboutMoneyPage />*/}
              </Route>
              <Route path="/contact">
                <ContactPage />
              </Route>
              <Route path="/about">
                <NotReadyYetPage />
              </Route>
              <Route path="/notes">
                <NotReadyYetPage />
                {/*<NotesPage />*/}
              </Route>
              <Route path="/projects">
                <ProjectsPage />
              </Route>
              <Route path="/order">
                <OrderPage />
              </Route>
              <Route path="/thank-you">
                <ThankYouPage />
              </Route>
              <Route path="/">
                <HomePage />
              </Route>
            </Switch>
          </div>
          <div className="App-footer">
            <ContentBlock>
              <h3><span>Keep in touch</span></h3>
              <form
                action="https://xyz.us5.list-manage.com/subscribe/post?u=d69a5696d2d554b41abeec766&amp;id=7456721a93"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                target="_blank"
                novalidate
              >
                <div className="mailing-list-section">
                  <div className="mailing-list-text">
                    The best way to stay up-to-date with our latest projects is to subscribe to our mailing list. These emails are very irregular and are only sent out when there is something important enough to say.
                  </div>
                  <div className="mailing-list-form">
                    <input
                      type="email"
                      value={this.state.email}
                      name="EMAIL"
                      id="mce-EMAIL"
                      placeholder="Email Address"
                      className="mailing-list-email"
                      required
                      onChange={this.handleInputChange}
                    />
                    <div
                      style={{position: 'absolute', left: '-5000px'}}
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_d69a5696d2d554b41abeec766_7456721a93"
                        tabindex="-1"
                        value=""
                      />
                    </div>
                    <input
                      type="submit"
                      value="Go"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="mailing-list-submit"
                    />
                  </div>
                </div>
              </form>
            </ContentBlock>
            <ContentBlock>
              <div className="no-tracking-notice">
                Hey! You are <b>not</b> being tracked. This website does <b>not</b> use cookies or any other form of tracking or information gathering technique for any commercial, non-commercial, analytics or research purposes. Just so you know!
              </div>
            </ContentBlock>
            <ContentBlock className='App-end-footer' theme='dark'>
              <div className='footer-logo'>
                <img src={logo_light} alt="logo" />
              </div>
              <div className='footer-copyright'>
                Copyright &copy; {new Date().getFullYear()}
              </div>
            </ContentBlock>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
