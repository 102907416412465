import React from 'react';
import { Link } from 'react-router-dom';

import ContentBlock from '../../components/ContentBlock';

import IMG_3346_LOW from '../../assets/project-photos/one-liner/IMG_3346-low.jpg';
import IMG_3346 from '../../assets/project-photos/one-liner/IMG_3346.jpg';
import IMG_3356_LOW from '../../assets/project-photos/one-liner/IMG_3356-low.jpg';
import IMG_3356 from '../../assets/project-photos/one-liner/IMG_3356.jpg';
import IMG_3357_LOW from '../../assets/project-photos/one-liner/IMG_3357-low.jpg';
import IMG_3357 from '../../assets/project-photos/one-liner/IMG_3357.jpg';
import IMG_3360_LOW from '../../assets/project-photos/one-liner/IMG_3360-low.jpg';
import IMG_3360 from '../../assets/project-photos/one-liner/IMG_3360.jpg';
import IMG_3362_LOW from '../../assets/project-photos/one-liner/IMG_3362-low.jpg';
import IMG_3362 from '../../assets/project-photos/one-liner/IMG_3362.jpg';
import IMG_3363_LOW from '../../assets/project-photos/one-liner/IMG_3363-low.jpg';
import IMG_3363_MID from '../../assets/project-photos/one-liner/IMG_3363-mid.jpg';
import IMG_3363 from '../../assets/project-photos/one-liner/IMG_3363.jpg';
import IMG_3364_LOW from '../../assets/project-photos/one-liner/IMG_3364-low.jpg';
import IMG_3364 from '../../assets/project-photos/one-liner/IMG_3364.jpg';
import IMG_3365_LOW from '../../assets/project-photos/one-liner/IMG_3365-low.jpg';
import IMG_3365 from '../../assets/project-photos/one-liner/IMG_3365.jpg';
import IMG_3372_LOW from '../../assets/project-photos/one-liner/IMG_3372-low.jpg';
import IMG_3372 from '../../assets/project-photos/one-liner/IMG_3372.jpg';
import IMG_3373_LOW from '../../assets/project-photos/one-liner/IMG_3373-low.jpg';
import IMG_3373 from '../../assets/project-photos/one-liner/IMG_3373.jpg';
import IMG_3374_LOW from '../../assets/project-photos/one-liner/IMG_3374-low.jpg';
import IMG_3374 from '../../assets/project-photos/one-liner/IMG_3374.jpg';
import IMG_3375_LOW from '../../assets/project-photos/one-liner/IMG_3375-low.jpg';
import IMG_3375 from '../../assets/project-photos/one-liner/IMG_3375.jpg';
import IMG_3378_LOW from '../../assets/project-photos/one-liner/IMG_3378-low.jpg';
import IMG_3378 from '../../assets/project-photos/one-liner/IMG_3378.jpg';
import IMG_3379_LOW from '../../assets/project-photos/one-liner/IMG_3379-low.jpg';
import IMG_3379 from '../../assets/project-photos/one-liner/IMG_3379.jpg';
import IMG_3381_LOW from '../../assets/project-photos/one-liner/IMG_3381-low.jpg';
import IMG_3381 from '../../assets/project-photos/one-liner/IMG_3381.jpg';
import IMG_3383_LOW from '../../assets/project-photos/one-liner/IMG_3383-low.jpg';
import IMG_3383 from '../../assets/project-photos/one-liner/IMG_3383.jpg';
import IMG_3385_LOW from '../../assets/project-photos/one-liner/IMG_3385-low.jpg';
import IMG_3385 from '../../assets/project-photos/one-liner/IMG_3385.jpg';
import IMG_3386_LOW from '../../assets/project-photos/one-liner/IMG_3386-low.jpg';
import IMG_3386 from '../../assets/project-photos/one-liner/IMG_3386.jpg';
import IMG_3389_LOW from '../../assets/project-photos/one-liner/IMG_3389-low.jpg';
import IMG_3389 from '../../assets/project-photos/one-liner/IMG_3389.jpg';
import IMG_3390_LOW from '../../assets/project-photos/one-liner/IMG_3390-low.jpg';
import IMG_3390 from '../../assets/project-photos/one-liner/IMG_3390.jpg';
import IMG_3391_LOW from '../../assets/project-photos/one-liner/IMG_3391-low.jpg';
import IMG_3391 from '../../assets/project-photos/one-liner/IMG_3391.jpg';
import IMG_3392_LOW from '../../assets/project-photos/one-liner/IMG_3392-low.jpg';
import IMG_3392 from '../../assets/project-photos/one-liner/IMG_3392.jpg';
import IMG_3393_LOW from '../../assets/project-photos/one-liner/IMG_3393-low.jpg';
import IMG_3393 from '../../assets/project-photos/one-liner/IMG_3393.jpg';
import IMG_3396_LOW from '../../assets/project-photos/one-liner/IMG_3396-low.jpg';
import IMG_3396 from '../../assets/project-photos/one-liner/IMG_3396.jpg';
import IMG_3406_LOW from '../../assets/project-photos/one-liner/IMG_3406-low.jpg';
import IMG_3406 from '../../assets/project-photos/one-liner/IMG_3406.jpg';
import IMG_3408_LOW from '../../assets/project-photos/one-liner/IMG_3408-low.jpg';
import IMG_3408 from '../../assets/project-photos/one-liner/IMG_3408.jpg';
import IMG_3411_LOW from '../../assets/project-photos/one-liner/IMG_3411-low.jpg';
import IMG_3411 from '../../assets/project-photos/one-liner/IMG_3411.jpg';
import IMG_3412_LOW from '../../assets/project-photos/one-liner/IMG_3412-low.jpg';
import IMG_3412 from '../../assets/project-photos/one-liner/IMG_3412.jpg';
import IMG_3415_LOW from '../../assets/project-photos/one-liner/IMG_3415-low.jpg';
import IMG_3415 from '../../assets/project-photos/one-liner/IMG_3415.jpg';
import IMG_3416_LOW from '../../assets/project-photos/one-liner/IMG_3416-low.jpg';
import IMG_3416 from '../../assets/project-photos/one-liner/IMG_3416.jpg';
import IMG_3427_LOW from '../../assets/project-photos/one-liner/IMG_3427-low.jpg';
import IMG_3427 from '../../assets/project-photos/one-liner/IMG_3427.jpg';
import IMG_3430_LOW from '../../assets/project-photos/one-liner/IMG_3430-low.jpg';
import IMG_3430 from '../../assets/project-photos/one-liner/IMG_3430.jpg';
import IMG_3432_LOW from '../../assets/project-photos/one-liner/IMG_3432-low.jpg';
import IMG_3432 from '../../assets/project-photos/one-liner/IMG_3432.jpg';
import IMG_3434_LOW from '../../assets/project-photos/one-liner/IMG_3434-low.jpg';
import IMG_3434 from '../../assets/project-photos/one-liner/IMG_3434.jpg';
import IMG_3441_LOW from '../../assets/project-photos/one-liner/IMG_3441-low.jpg';
import IMG_3441 from '../../assets/project-photos/one-liner/IMG_3441.jpg';
import IMG_3442_LOW from '../../assets/project-photos/one-liner/IMG_3442-low.jpg';
import IMG_3442 from '../../assets/project-photos/one-liner/IMG_3442.jpg';
import IMG_3443_LOW from '../../assets/project-photos/one-liner/IMG_3443-low.jpg';
import IMG_3443 from '../../assets/project-photos/one-liner/IMG_3443.jpg';
import IMG_3447_LOW from '../../assets/project-photos/one-liner/IMG_3447-low.jpg';
import IMG_3447 from '../../assets/project-photos/one-liner/IMG_3447.jpg';
import IMG_3462_LOW from '../../assets/project-photos/one-liner/IMG_3462-low.jpg';
import IMG_3462 from '../../assets/project-photos/one-liner/IMG_3462.jpg';
import IMG_3471_LOW from '../../assets/project-photos/one-liner/IMG_3471-low.jpg';
import IMG_3471 from '../../assets/project-photos/one-liner/IMG_3471.jpg';
import IMG_3458_LOW from '../../assets/project-photos/one-liner/IMG_3458-low.jpg';
import IMG_3458 from '../../assets/project-photos/one-liner/IMG_3458.jpg';
import IMG_3535_LOW from '../../assets/project-photos/one-liner/IMG_3535-low.jpg';
import IMG_3535 from '../../assets/project-photos/one-liner/IMG_3535.jpg';
import IMG_3537_LOW from '../../assets/project-photos/one-liner/IMG_3537-low.jpg';
import IMG_3537 from '../../assets/project-photos/one-liner/IMG_3537.jpg';
import IMG_3542_LOW from '../../assets/project-photos/one-liner/IMG_3542-low.jpg';
import IMG_3542 from '../../assets/project-photos/one-liner/IMG_3542.jpg';
import IMG_3603_LOW from '../../assets/project-photos/one-liner/IMG_3603-low.jpg';
import IMG_3603 from '../../assets/project-photos/one-liner/IMG_3603.jpg';
import IMG_3599_LOW from '../../assets/project-photos/one-liner/IMG_3599-low.jpg';
import IMG_3599 from '../../assets/project-photos/one-liner/IMG_3599.jpg';
import IMG_3604_LOW from '../../assets/project-photos/one-liner/IMG_3604-low.jpg';
import IMG_3604 from '../../assets/project-photos/one-liner/IMG_3604.jpg';
import IMG_3605_LOW from '../../assets/project-photos/one-liner/IMG_3605-low.jpg';
import IMG_3605 from '../../assets/project-photos/one-liner/IMG_3605.jpg';

let OneLinerPage = () => (
  <div>
    <ContentBlock theme='light'>
      <div className="page-heading">
        <span className="page-heading-title">
          The 'One-Liner' Stool
        </span>
        <span className="page-heading-subtitle">
          Minimalist, yet practical
        </span>
      </div>

      <a href={IMG_3363} target="_blank">
        <div className="full-width-photo" style={{
          backgroundImage: `url(${IMG_3363_MID})`,
        }}/>
      </a>

      <p></p>

      <div className="highlight">
        <b>Tip:</b> Tap or click on the photos for higher quality versions. Photos will open in a new browser tab.
      </div>

      <a href={IMG_3374} target="_blank">
        <div className="half-width-photo align-right" style={{
          backgroundImage: `url(${IMG_3374_LOW})`,
        }}/>
      </a>

      <p>
        The One-Liner is a minimalist, yet practical stool. Its narrow base and footprint makes it ideal for places where space is limited. It can be stored away in a few different ways, from hanging off the wall to standing on its back, but in all cases it wouldn’t take too much of a space.
      </p>
      <p>
        Using the One-Liner is also quite fun. While your weight is supported by the stool, your legs are still in charge of keeping you balanced. The small profile of the stool comes handy once again: There are no unessential legs, back support or armrests to get in the way of your motion. Most people say the One-Liner helps them with maintaining a correct posture too.
      </p>
      <p>
        Although not really a practical function of the product, the One-Liner has a little bit of a personality too. It isn’t just a cheap portable stool which is going to last only an hour or so before looking too old and out-dated. It’s not also an expensive high-tech product made with expensive machines and rare materials. The odd-looking one-legged design is probably new to most people too. The One-Liner is more of a companion rather than a purely functional product and for some that’s already enough to desire one.
      </p>

      <p style={{borderTop: '1px solid black', paddingTop: '1rem'}}>
        The product photography was made possible with the help of the lovely people at <a href="https://www.instagram.com/korayhussein/" target="_blank">Koray Hussein Photography</a>, <a href="https://www.instagram.com/about_the_bike_east_london/" target="_blank">About the BIKE - EAST</a> and <a href="https://www.instagram.com/eliteevolution/" target="_blank">Elite Evolution</a>.
      </p>
    </ContentBlock>

    <ContentBlock>
      <h3>
        <span>The stool</span>
      </h3>
      <div className="photo-gallery">
        <a href={IMG_3360} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3360_LOW})`,
          }}/>
        </a>

        <a href={IMG_3362} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3362_LOW})`,
          }}/>
        </a>

        <a href={IMG_3363} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3363_LOW})`,
          }}/>
        </a>

        <a href={IMG_3364} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3364_LOW})`,
          }}/>
        </a>

        <a href={IMG_3365} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3365_LOW})`,
          }}/>
        </a>

        <a href={IMG_3372} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3372_LOW})`,
          }}/>
        </a>

        <a href={IMG_3373} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3373_LOW})`,
          }}/>
        </a>

        <a href={IMG_3374} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3374_LOW})`,
          }}/>
        </a>

        <a href={IMG_3375} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3375_LOW})`,
          }}/>
        </a>

        <a href={IMG_3378} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3378_LOW})`,
          }}/>
        </a>

        <a href={IMG_3379} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3379_LOW})`,
          }}/>
        </a>

        <a href={IMG_3381} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3381_LOW})`,
          }}/>
        </a>

        <a href={IMG_3383} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3383_LOW})`,
          }}/>
        </a>

        <a href={IMG_3385} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3385_LOW})`,
          }}/>
        </a>

        <a href={IMG_3386} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3386_LOW})`,
          }}/>
        </a>

        <a href={IMG_3389} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3389_LOW})`,
          }}/>
        </a>

        <a href={IMG_3390} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3390_LOW})`,
          }}/>
        </a>

        <a href={IMG_3391} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3391_LOW})`,
          }}/>
        </a>

        <a href={IMG_3392} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3392_LOW})`,
          }}/>
        </a>

        <a href={IMG_3393} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3393_LOW})`,
          }}/>
        </a>

        <a href={IMG_3396} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3396_LOW})`,
          }}/>
        </a>
      </div>
    </ContentBlock>

    <ContentBlock>
      <h3>
        <span>In action</span>
      </h3>

      <div className="photo-gallery">
        <a href={IMG_3346} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3346_LOW})`,
          }}/>
        </a>

        <a href={IMG_3356} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3356_LOW})`,
          }}/>
        </a>

        <a href={IMG_3357} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3357_LOW})`,
          }}/>
        </a>

        <a href={IMG_3406} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3406_LOW})`,
          }}/>
        </a>

        <a href={IMG_3408} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3408_LOW})`,
          }}/>
        </a>

        <a href={IMG_3411} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3411_LOW})`,
          }}/>
        </a>

        <a href={IMG_3412} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3412_LOW})`,
          }}/>
        </a>

        <a href={IMG_3415} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3415_LOW})`,
          }}/>
        </a>

        <a href={IMG_3416} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3416_LOW})`,
          }}/>
        </a>

        <a href={IMG_3458} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3458_LOW})`,
          }}/>
        </a>

        <a href={IMG_3462} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3462_LOW})`,
          }}/>
        </a>

        <a href={IMG_3471} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3471_LOW})`,
          }}/>
        </a>

        <a href={IMG_3535} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3535_LOW})`,
          }}/>
        </a>

        <a href={IMG_3537} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3537_LOW})`,
          }}/>
        </a>

        <a href={IMG_3542} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3542_LOW})`,
          }}/>
        </a>

      </div>
    </ContentBlock>

    <ContentBlock>
      <h3>
        <span>Removable upholstery (under development)</span>
      </h3>
      <div className="photo-gallery">
        <a href={IMG_3603} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3603_LOW})`,
          }}/>
        </a>

        <a href={IMG_3599} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3599_LOW})`,
          }}/>
        </a>

        <a href={IMG_3604} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3604_LOW})`,
          }}/>
        </a>

        <a href={IMG_3605} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3605_LOW})`,
          }}/>
        </a>
      </div>
    </ContentBlock>

    <ContentBlock>
      <h3>
        <span>Stability test</span>
      </h3>
      <div className="photo-gallery">
        <a href={IMG_3427} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3427_LOW})`,
          }}/>
        </a>

        <a href={IMG_3430} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3430_LOW})`,
          }}/>
        </a>

        <a href={IMG_3432} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3432_LOW})`,
          }}/>
        </a>

        <a href={IMG_3434} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3434_LOW})`,
          }}/>
        </a>

        <a href={IMG_3441} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3441_LOW})`,
          }}/>
        </a>

        <a href={IMG_3442} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3442_LOW})`,
          }}/>
        </a>

        <a href={IMG_3443} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3443_LOW})`,
          }}/>
        </a>

        <a href={IMG_3447} target="_blank">
          <div className="photo-gallery-item" style={{
            backgroundImage: `url(${IMG_3447_LOW})`,
          }}/>
        </a>

      </div>
    </ContentBlock>

  </div>
);

export default OneLinerPage;
