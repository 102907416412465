import React from 'react';
import { Link } from 'react-router-dom';

import ContentBlock from '../../components/ContentBlock';

import ORIGINAL_DRAWING_01 from '../../assets/project-photos/monk/original_drawing_01.jpg';

let MonkPage = () => (
  <div>
    <ContentBlock theme='light'>
      <div className="page-heading">
        <span className="page-heading-title">
          The Monk Desk
        </span>
        {/*<span className="page-heading-subtitle">
          
        </span>*/}
      </div>

      <a href={ORIGINAL_DRAWING_01} target="_blank">
        <div className="full-width-photo" style={{
          backgroundImage: `url(${ORIGINAL_DRAWING_01})`,
        }}/>
      </a>

      <div className="page-abstract">
        <p>
          The Monk desk has a very simple and traditional looking design, however, with a surprise element embedded in the design of the desk. This is a Work-in-Progress project.
        </p>
      </div>
    </ContentBlock>
  </div>
);

export default MonkPage;
