import React from 'react';
import { Link } from 'react-router-dom';

import ContentBlock from '../components/ContentBlock';
import "../assets/styles/pages/HomePage.css";

let HomePage = () => (
  <ContentBlock>
    <div className="highlight">
      <b>Under Construction:</b> This website is still under active development. Please come back in a couple of weeks for more content!
    </div>

    {/* Featured Project */}
    <h3><span>Featured Project</span></h3>
    <div className="featured-project">
      <div className="featured-project-cover">
        <Link to="/projects/never-forgotten">
          <div className="never-forgotten-home-page-showcase-cover">
          </div>
        </Link>
      </div>
      <div className="featured-project-text">
        <b>The 'Never Forgotten' Desk</b>
        <br />
        <p>
          The Never Forgotten is a desk inspired by aviation. The side-profile somewhat loosely replicates the side-profile of a biplane; However, it deliberately doesn’t copy the exact elements, forms or the construction methods of an airworthy aeroplane.        </p>
        <p>
          The design is a tribute to the brave people who not only made flying and aviation possible, safer and more accessible for all of us, but also protected our lives and future at the cost of sacrificing their own.
          &nbsp;<Link to="/projects/never-forgotten">Read more...</Link>
        </p>
      </div>
    </div>

    {/* Most Recent */}
    <h3><span>Recent Projects</span></h3>
    <div className="most-recent-project">
      <div className="most-recent-project-cover">
        <Link to="/projects/one-liner">
          <div className="one-liner-home-page-showcase-cover">
          </div>
        </Link>
      </div>
      <div className="most-recent-project-text">
        <b>The 'One-Liner' Stool</b>
        <br />
        <p>
          The One-Liner is a minimalist, yet practical stool. Its narrow base and footprint makes it ideal for places where space is limited. It can be stored away in a few different ways, from hanging off the wall to standing on its back, but in all cases it wouldn’t take too much of a space.
        </p>
        <p>
          Using the One-Liner is also quite fun. While your weight is supported by the stool, your legs are still in charge of keeping you balanced. The small profile of the stool comes handy once again: There are no unessential legs, back support or armrests to get in the way of your motion. Most people say the One-Liner helps them with maintaining a correct posture too.
          &nbsp;<Link to="/projects/one-liner">Read more...</Link>
        </p>
        
      </div>
    </div>
  </ContentBlock>
);

export default HomePage;
