import ContentBlock from '../components/ContentBlock';

let ContactPage = () => (
  <ContentBlock>
    <div>
      <h3><span>Contact Details</span></h3>
      <p>
        <b>Email:</b> hi@klyn.xyz
        <br />
        <b>Phone:</b> +44 (0)7502157200
        <br />
        <b>Workshop Address:</b> <br />
        KLYN<br />
        86b Wallis Road<br />
        London<br />
        United Kingdom<br />
        E9 5LN<br />
        <br />
        <b>Please note:</b> Workshop visits are by appointment only.
      </p>
    </div>
  </ContentBlock>
);

export default ContactPage;
