import ContentBlock from '../components/ContentBlock';

let OrderPage = () => (
  <ContentBlock>
    <div>
        <h3><span>How to place an order</span></h3>
        <p>
            <b>Step One:</b> Fill out our <b>Project Assessment</b> questionnaire. This is a required step for every project we do and it only takes a few minutes to complete. <a href="https://forms.gle/7rutkth5uGRvZoDs6" target="_blank">Click here to open the questionnaire</a>.
        </p>
        <p>
            <b>Step Two:</b> Wait for someone from our team to get in touch with you. This usually happens the next working day. You may talk to an artist, a designer or a maker from our team, depending on your project and the stage you are at, but regardless of their position and expertise, they should be able to tell you if we are able to work on your project or not and what our initial cost and delivery time estimates are.
        </p>
        <p>
            <b>Step Three:</b> Depending on your project you may need to book a <b>Project Consultation</b> and/or a <b>Design and Review</b> meeting with us. This is where we discuss your project in detail and provide a 3D model to be included in the contract. Although this may sound intimidating, it is one of the most fun parts of every project where we get to explore various designs and ideas and learn from each other. You will be told in Step Two, if you do require to book any or both of these meetings before going forward.
        </p>
        <p>
            <b>Step Four:</b> Sign the contract and let the magic of skills and teamwork bring something beautiful to life!
        </p>
    </div>
  </ContentBlock>
);

export default OrderPage;
